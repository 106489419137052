import {
  React,
  useEffect,
  loadable,
  Route,
  Switch,
  BrowserRouter as Router
} from './libraries'
import './App.css'
import { ScrollToTop, useFetch } from './utils'
import { api } from './helpers'

const App = () => {
  const Home = loadable(() => import('./pages/home'))
  const Article = loadable(() => import('./pages/article'))
  const DetailArticle = loadable(() => import('./pages/article/detail'))
  const Info = loadable(() => import('./pages/info'))
  const DetailInfo = loadable(() => import('./pages/info/detail'))
  const About = loadable(() => import('./pages/about'))
  const Merchandised = loadable(() => import('./pages/merchandise'))
  const Gallery = loadable(() => import('./pages/gallery'))
  const Registration = loadable(() => import('./pages/register'))
  const Agenda = loadable(() => import('./pages/agenda'))
  const Login = loadable(() => import('./pages/Login.js'))
  const Profile = loadable(() => import('./pages/Profile.js'))
  const StrukturOrganisasi = loadable(() => import('./pages/StrukturOrganisasi'))

  // const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  
  useEffect(() => {
    api.post('api/app_token', { company_name: 'iskindo', comp_key: 'iskindo' })
    .then(res => {
      localStorage.setItem('appToken', res.data.data)
    })
    .catch(err => {
      console.log('err', err)
    })
  }, [])

  return (
    <Router basename='/iskindo'>
      <ScrollToTop />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/article' exact component={Article} />
        <Route path='/detail-article/:slug' exact component={DetailArticle} />
        <Route path='/info' exact component={Info} />
        <Route path='/detail-info/:slug' exact component={DetailInfo} />
        <Route path='/merchandise' exact component={Merchandised} />
        <Route path='/gallery' exact component={Gallery} />
        <Route path='/about' exact component={About} />
        <Route path='/registration' exact component={Registration} />
        <Route path='/agenda' exact component={Agenda} />

        <Route path='/kebijakan' exact component={Agenda} />
        <Route path='/kepengurusan' exact component={Agenda} />
        <Route path='/profile' exact component={Profile} />
        <Route path='/struktur-organisasi' exact component={StrukturOrganisasi} />
        <Route path='/login' exact component={Login} />
      </Switch>
    </Router>
  )
}

export default App
