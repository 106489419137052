import {
  useEffect,
  useState,
  useRef
} from '../libraries'
import { api } from '../helpers'

const useFetch = (body) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  // const cache = useRef({})
// console.log('body', body)
  const _fetchingData = () => {
    const fetch = body.map(item => {
      setLoading(true)
      api[item.method](item.url, item.params)
        .then(response => {
          setData(prevState => ({
            ...prevState,
            [item.module]: [response.data.data]
          }))
          setLoading(!loading)
        })
        .catch(error => {
          console.log(error)
          // setError(error.response.data.message)
          setLoading(!loading)
        })
    })

    return fetch
  }

  useEffect(() => {
    let cancelRequest = false
    if (!body) return
    _fetchingData()
    return function cleanup () {
      cancelRequest = true
    }
  }, [])
  
  return { data, loading, error }
}

export default useFetch
